import {ExternalLinkIcon} from '@chakra-ui/icons';
import {
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'

import {TermsAndConditionsPdf} from "./TermsAndConditionsPdf";


export function TermsAndConditionsModal() {
  const {isOpen, onOpen, onClose} = useDisclosure({defaultIsOpen: false})

  return (
    <>
      <Link onClick={onOpen} color="blue.700">Ver términos y condiciones</Link>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay/>
        <ModalContent mx={5}>
          <ModalHeader>Términos y condiciones</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <HStack justifyContent="center">
              <Link href="https://terminos.activame.tg" isExternal color="blue.700">
                Ver en nueva pestaña
                <ExternalLinkIcon mx='2px'/>
              </Link>
            </HStack>
            <TermsAndConditionsPdf/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}