import {
  Button,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Radio,
  RadioGroup,
  Select,
  Stack,
  useToast
} from "@chakra-ui/react";
import React, {useCallback, useState} from "react";
import {TermsAndConditionsModal} from "./TermsAndConditionsModal";
import axios from "axios";

export function SubscriptionForm() {
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [action, setAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast()

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    if (!country || !phoneNumber || !action) {
      toast({
        title: 'Error',
        description: "Por favor llena todos los campos",
        status: 'error',
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    const command = action === 'cancelar' ? action : country;
    axios.post('https://optin.activame.tg/api/v1/suscripciones/suscribirnumero', {
      from: phoneNumber,
      body: `${command},PORTAL`,
    }, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }).then(() => {
      toast({
        title: 'Solicitud enviada',
        description: "Tu solicitud ha sido enviada con éxito",
        position: 'top',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setAction('');
      setCountry('');
      setPhoneNumber('')
    })
      .catch(() => {
        toast({
          title: 'Error',
          position: 'top',
          description: "Ocurrió un error al enviar tu solicitud",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [action, country, phoneNumber, toast]);

  return <>
    <FormControl>
      <FormLabel>País</FormLabel>
      <Select placeholder="Selecciona un pais" onChange={evt => setCountry(evt.target.value)}>
        <option value="502">Guatemala</option>
        <option value="504">El Salvador</option>
        <option value="505">Honduras</option>
        <option value="503">Nicaragua</option>
      </Select>
    </FormControl>
    <FormControl>
      <FormLabel>Numero de teléfono</FormLabel>
      <NumberInput onChange={(valueAsString) => setPhoneNumber(valueAsString)}>
        <NumberInputField placeholder="####-####"/>
      </NumberInput>
    </FormControl>
    <FormControl>
      <FormLabel>Acción</FormLabel>
      <RadioGroup size='lg' onChange={value => setAction(value)}>
        <Stack direction='row'>
          <Radio value='-'>Crear suscripción</Radio>
          <Radio value='cancelar'>Eliminar suscripción</Radio>
        </Stack>
      </RadioGroup>
    </FormControl>
    <Button w="100%" colorScheme="green" onClick={onSubmit} isLoading={isLoading}>Guardar</Button>
    <TermsAndConditionsModal/>
  </>
}