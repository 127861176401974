import React from 'react';
import {Box, Container, VStack} from '@chakra-ui/react';
import {ReactComponent as TigoLogo} from './img/tigo-logo-white.svg';
import {SubscriptionForm} from "./components/SubscriptionForm";

function App() {
  return (
    <Container mt={25}>
      <VStack gap={5} mb={100}>
        <Box width={150} aspectRatio={1}>
          <TigoLogo width="100%" height="100%"/>
        </Box>
        <VStack gap={5} bg="white" w="100%" p={5} borderRadius={15}>
          <SubscriptionForm/>
        </VStack>
      </VStack>
    </Container>
  );
}

export default App;
