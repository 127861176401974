import {Document, Page, pdfjs} from "react-pdf";
import {useCallback, useLayoutEffect, useMemo, useRef, useState} from "react";
import type {PDFDocumentProxy} from "pdfjs-dist";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {HStack} from "@chakra-ui/react";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


export function TermsAndConditionsPdf() {
  const containerRef = useRef<HTMLDivElement>(null);

  const [numPages, setNumPages] = useState<number>();
  const [containerWidth, setContainerWidth] = useState<number>();


  useLayoutEffect(() => {
    setContainerWidth(containerRef.current?.getBoundingClientRect().width);
  }, []);


  const onDocumentLoadSuccess = useCallback(({numPages: nextNumPages}: PDFDocumentProxy) => {
    setNumPages(nextNumPages);
  }, []);


  const pdfPages = useMemo(() => {
    if (!numPages || !containerWidth) {
      return null;
    }
    return Array.from(new Array(numPages), (_el, index) => (
      <Page
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={containerWidth ? Math.min(containerWidth, 800) : 800}
      />
    ));
  }, [containerWidth, numPages])

  return <HStack ref={containerRef} justifyContent="center">
    <Document file="/terminos.pdf" onLoadSuccess={onDocumentLoadSuccess}>
      {pdfPages}
    </Document>
  </HStack>
}